<template>
  <v-container class="mt-16">
    <div class="pl pr">
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Invoices</h2>

          <v-spacer></v-spacer>
          <v-btn small @click="print_unpaid_invoices">Unpaid Invoices</v-btn>
          <add-invoice-card :is_invoice="1" />
          <v-btn
            color=""
            class=""
            icon
            x-large
            @click="refresh"
            :loading="loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <div>
            <v-text-field
              label="Search "
              prepend-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
            <v-data-table
              :items="all_invoices"
              :headers="headers"
              :search="search"
              :loading="loading"
            >
              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <payables-modal :invoice="item" />

                  <v-btn @click="open_payment(item)" color="yellow" icon
                    ><v-icon>mdi-cash-multiple</v-icon></v-btn
                  >
                  <print-modal-card :item="item" />

                  <v-btn
                    v-if="user.access_level >= 4"
                    icon
                    @click="del_invoice(item)"
                    color="red"
                    class="mr-1"
                    :loading="loading"
                    ><v-icon class="mr-2">mdi-delete</v-icon></v-btn
                  >
                </div>
              </template>
              <template v-slot:item.invoice_status="{ item }">
                <span
                  :class="
                    item.invoice_status == 'Paid' ? 'green--text' : 'red--text'
                  "
                  >{{ item.invoice_status }}</span
                >
              </template>
              <template v-slot:item.is_invoice="{ item }">
                <v-chip color="blue" class="white--text" v-if="item.is_invoice"
                  >Invoice</v-chip
                >
                <v-chip color="red" class="white--text" v-else>Bill</v-chip>
              </template>
            </v-data-table>
          </div>
          <add-payment
            v-if="dialog"
            :invoice="selected_invoice"
            @close_payment="dialog = false"
          />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// import { defineAsyncComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import AddInvoiceCard from "../components/Invoice/addInvoiceCard.vue";
import PrintModalCard from "../components/Invoice/printModalCard.vue";
import payablesModal from "../components/Payment/payablesModal.vue";

export default {
  components: {
    payablesModal,

    AddInvoiceCard,
    addPayment: () => import("../components/Payment/addPayment.vue"),
    PrintModalCard,
  },
  data() {
    return {
      selected_invoice: "",
      dialog: false,
      ready: false,
      search: "",
      loading: false,
      headers: [
        { text: "Action", value: "action", width: "80" },
        { text: "ID", value: "id" },
        { text: "Invoice Code", value: "invoice_code" },
        { text: "Account Name", value: "customer.customer_name" },
        { text: "Invoice Date", value: "invoice_date" },
        { text: "Invoice Status", value: "invoice_status" },
        { text: "Balance", value: "balance" },
        { text: "VAT", value: "vat" },
        { text: "Total Amount", value: "total_amount" },

        { text: "Type", value: "is_invoice" },
        { text: "Purpose", value: "purpose" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
      all_invoices: "invoice/all_invoices",
      user: "auth/user",
    }),
  },
  methods: {
    print_unpaid_invoices() {
      let url =
        this.default_server_url +
        "/print_unpaid_invoices/" +
        this.user.branch_id;
      window.open(url);
    },
    open_payment(item) {
      this.selected_invoice = item;
      this.dialog = true;
    },
    async del_invoice(item) {
      this.loading = true;
      let y = window.confirm("Are you sure you want to delete this invoice?");
      if (y) {
        await this.delete_invoice(item);
        this.loading = false;
        alert("Successfully deleted an invoice");
      } else {
        this.loading = false;
      }
    },
    refresh() {
      this.loading = true;
      this.get_all_invoices().then(() => {
        this.loading = false;
      });

      return;
    },
    ...mapActions({
      get_all_invoices: "invoice/get_all_invoices",
      delete_invoice: "invoice/delete_invoice",
    }),
  },
  async created() {
    this.loading = true;
    if (this.all_invoices.length <= 0) {
      await this.get_all_invoices();
    }
    this.loading = false;
  },
};
</script>

<style></style>
