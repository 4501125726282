<template>
  <div>
    <v-btn small class="ml-2" color="" @click="dialog = true"
      ><v-icon>mdi-format-list-numbered</v-icon>Delivery Receipt</v-btn
    >
    <!-- <v-btn @click="dialog = true"></v-btn> -->
    <v-dialog v-model="dialog" persistent width="1100">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-img src="@/assets/jmbcomputers.png" max-width="30%"></v-img>
            <v-spacer></v-spacer>
            <v-btn small class="mr-2" @click="include_warranty('desktop')"
              >Desktop Warranty</v-btn
            >
            <v-btn small class="mr-2" @click="include_warranty('desktop')"
              >Printer Warranty</v-btn
            >
            <v-btn small class="mr-2" @click="include_warranty('desktop')"
              >Laptop Warranty</v-btn
            >
          </div>
          <div class="d-flex">
            <p>Invoice Items</p>
            <v-spacer></v-spacer>

            <v-checkbox
              v-model="form.display_warranty"
              label="Display Warranty"
              value="display"
            ></v-checkbox>
          </div>

          <div v-for="item in item.invoice_items" :key="item.id">
            <v-row class="mt-n10">
              <v-col cols="1">
                <h3 class="mt-5">{{ item.id }}</h3></v-col
              >
              <v-col cols="2">
                <v-text-field
                  label="Serial Number"
                  class="ml-2"
                  value="N/A"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  readonly
                  class="ml-2"
                  :value="item.item.item_name"
                  label="Item Name"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  class="ml-2"
                  :value="item.item.selling_price"
                  label="Price"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  class="ml-2"
                  :value="item.quantity"
                  label="Quantity"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  class="ml-2"
                  :value="item.amount"
                  label="Total Amount"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="ml-2"
                  :value="get_warranty(item)"
                  label="Warranty"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-textarea
            prepend-icon="mdi-pen"
            :value="item.purpose"
            label="Remarks/Purpose"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Print</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">printDeliveryReceiptModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  props: ["item"],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Item Description", value: "item.item_name" },
        // {text:"",value:""}
      ],
      dialog: false,
      loading: false,
      form: { display_warranty: "display" },
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    include_warranty(type) {
      return type;
    },
    get_warranty(item) {
      let warranty =
        item.item.warranty.warranty_count +
        "-" +
        item.item.warranty.warranty_duration;
      return warranty;
    },
    submit() {
      let x = window.confirm(
        "Are you sure you want to proceed with your request?"
      );
      if (x) {
        console.log(this.default_server_url);
        let url =
          this.default_server_url + "/print_delivery_receipt/" + this.item.id;
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
